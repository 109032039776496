<template>
  <div class="leftThreeChart" id="incomeStatistics1" ref='echart'></div>
</template>

<script>
export default {
  data() {
    return {
      
    };
  },
  components: {
   
  },
  computed: {
    
  },
  props: {
    size: {
      type: String,
      default: "width: 100%; height: 306px",
    },
    orderData: {
        default: ''
    }
  },
  watch: {
    orderData: {
      handler (value) {
        this.initCharts();
      },
      deep: true
    }
  },
  created() {},
  mounted() {
    this.initCharts();
  },
  methods: {
    initCharts() {
      var that = this;
    //   var chartDom = document.getElementById("incomeStatistics1");
      var chartDom = this.$refs.echart;
      var myChart = this.$echarts.init(chartDom);
      console.log(myChart, 'myChart')
      this.echartsDOM = myChart;
      var option = {
        title: {
          text: this.orderData.unit,
          color: "#9AA4B3",
          // left: "2%",
          padding: [10, 0, 0, 18],
          textStyle: {
            fontSize: 12,
            fontWeight: "400",
            color: "#9AA4B3"
          }
        },
        color: ["rgba(165, 184, 255, 1)", "rgba(255, 255, 255, 1)"], // 圆圈的样式
        // color: '#527BFF',
        tooltip: {
          trigger: "axis",
        },
        // legend: {
        //     right: 20,
        //     top: 0
        // },
        grid: [
          {
            left: '3%',
            right: '4%',
            bottom: '8%',
            top: '15%',
            containLabel: true
            // height: "55%",
          },
        ],
        xAxis: {
          type: "category",
          data: this.orderData.xData,
          axisPointer: {
            type: "shadow",
          },
          axisLine: {
            onZero: true,
            lineStyle: {
              type: "solid",
              color: "#EBEFF3", // x轴坐标线的颜色
              width: "1", // 坐标线的宽度
            },
          },
          axisLabel: {
            color: "#9AA4B3", // 刻度样式
          },
          // axisTick: {
          //   show: false // 刻度标尺
          // },
          boundaryGap: true, // x轴两边是否留空白，false不留，true留
        },

        yAxis: {
          offset: 0,
          min: function (val) {
            return that.orderData.Proportion ? 0 : null
          },
          max: function (val) {
            return that.orderData.Proportion && val.max <= 100 ? 100 : null
          }, 
          // min: 0,
          // max: 100,
          splitNumber: 5,
          // scale: true,
          axisTick: {
            show: false, // y轴坐标点是否消失
          },
          axisLine: {
            // y轴
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed", // 虚线
              color: "#DAE3EC", // x轴的虚线颜色
            },
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#94B4CA",
            },
            // formatter: `{value}`,
            formatter: function (val) {
                    return that.orderData.Proportion ? (val  + "%") : val;
                }
          },
        },
        // 鼠标滚动条
        // dataZoom: [
        //   {
        //     type: "slider",
        //     show: true,
        //     height: 4,
        //     left: "1%",
        //     right: "1%",
        //     bottom: "-2%",
        //     start: 0,
        //     end: 100,
        //     filterMode: 'empty',
        //     fillerColor: "rgba(78, 108, 167, 1)",
        //     backgroundColor: "rgba(41, 62, 103, 1)",
        //     borderColor: "none",
        //     textStyle: {
        //       color: "#d4ffff",
        //       fontSize: 11,
        //       border: "none",
        //     },
        //   },
        //   {
        //     type: "inside",
        //   },
        // ],
        series: [
          {
            name: this.orderData.seriesName,
            type: "line",
            smooth: true,
            areaStyle: {
              normal: {
                color: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(164, 196, 255, 0.8)", // 0% 处的颜色
                    },
                    {
                      offset: 0.5,
                      color: "rgba(164, 196, 255, 0.4)", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "rgba(131,168,255,0)", // 100% 处的颜色
                    },
                  ],
                  globalCoord: false, // 缺省为 false
                },
              },
            },
            itemStyle: {
              normal: {
                lineStyle: {
                  color: "#527BFF",
                  width: 2,
                },
              },
            },
            tooltip: {
              valueFormatter: function (value) {
                return that.orderData.Proportion ? (value  + "%") : value;
              },
            },
            data: this.orderData.yData
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener("resize", () => {
      this.echartsDOM.resize();
    });
    },
  },
};
</script>

<style lang="stylus" scoped>
 .leftThreeChart {
    // padding-top: 16px;
    height: 100%;
  }
</style>
